import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const Confirmacion = ({ title, message, acceptBtn, accept, cancelBtn, cancel }) => (
  <div className="sl-confirmacion">
    <div className="backdrop">
      <div className="card">
        <div className="card-body">
          <FontAwesomeIcon icon={faExclamationCircle} />
          <h5 className="title">{title}</h5>
          <p className="message">{message}</p>
          <div className="buttons">
            <button type="button" className="btn btn-sl-2" onClick={accept}>{acceptBtn}</button>
            <button type="button" className="btn btn-danger" onClick={cancel}>{cancelBtn}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

Confirmacion.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  acceptBtn: PropTypes.string,
  accept: PropTypes.func,
  cancelBtn: PropTypes.string,
  cancel: PropTypes.func,
};

Confirmacion.defaultProps = {
  title: '¿Esta seguro?',
  message: 'Debe confirmar antes de continuar.',
  acceptBtn: 'Continuar',
  accept: f => f,
  cancelBtn: 'Cancelar',
  cancel: f => f,
};

export default Confirmacion;
