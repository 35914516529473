import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { GOOGLE_API_KEY } from '../../../lib/constants';
import './styles.css';

class GoogleAutocomplete extends React.Component {
  state = {
    mapsReady: false,
    query: '',
    suggestions: [],
    cursor: -1,
    place: undefined,
    placeName: undefined,
  };

  componentDidMount() {
    this.initMaps();
  }

  initMaps = () => {
    const startServices = () => {
      // Start services
      this.defaultBounds = new window.google.maps.LatLngBounds( // bounds de Hermosillo, Son.
        { lat: 28.9875843, lng: -111.0847417 },
        { lat: 29.176726, lng: -110.89323179999997 },
      );
      this.geocoder = new window.google.maps.Geocoder();
      this.autocompleteService = new window.google.maps.places.AutocompleteService();
      this.setState({ mapsReady: true });
    };

    if (document.getElementById('google-maps-script')) {
      startServices();
      return;
    }

    const script = document.createElement('script');
    script.onload = () => { startServices(); };
    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`;
    script.id = 'google-maps-script';

    document.body.appendChild(script);
  }

  getSuggestions = (query) => {
    this.autocompleteService.getPlacePredictions({
      input: query,
      bounds: this.defaultBounds,
    }, (results, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        this.setState({ suggestions: results });
      }
    });
  }

  handleQueryChange = (event) => {
    const { target: { value } } = event;
    this.setState({ query: value, cursor: -1 }, () => {
      if (value.length < 4) {
        this.setState({
          suggestions: [],
          place: undefined,
          placeName: undefined,
        });
      } else if (value.length % 2 === 0) {
        this.getSuggestions(value);
      }
    });
  }

  handleQueryBlur = () => {
    const { place, placeName } = this.state;
    if (place) {
      this.setState({
        query: placeName,
      });
    }
  }

  handleKeys = (event) => {
    const { suggestions, cursor } = this.state;
    if (suggestions.length === 0) { return; }
    const ul = document.getElementById('googleAutocomplete_suggestions');
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (cursor === -1) {
          ul.childNodes[0].focus();
          this.setState({ cursor: 0 });
        } else if ((cursor + 1) < ul.childNodes.length) {
          ul.childNodes[cursor + 1].focus();
          this.setState({ cursor: cursor + 1 });
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (cursor > 0) {
          if ((cursor - 1) >= 0) {
            ul.childNodes[cursor - 1].focus();
            this.setState({ cursor: cursor - 1 });
          }
        }
        break;
      case 'Enter':
        if (cursor !== -1) {
          ul.childNodes[cursor].click();
        }
        break;
      default: break;
    }
  }

  placeSelected = (place) => {
    this.geocoder.geocode({ placeId: place.place_id }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        this.setState({
          suggestions: [],
          query: place.description,
          place: results[0],
          placeName: place.description,
        }, () => {
          this.toParent();
        });
      }
    });
  }

  toParent = () => {
    const { place } = this.state;
    const { getResult } = this.props;
    getResult(place);
  }

  clearForm = () => this.setState({
    query: '',
    suggestions: [],
    cursor: -1,
    place: undefined,
    placeName: undefined,
  });

  render() {
    const { query, suggestions } = this.state;

    return (
      <div className="googleAutocomplete">
        {/* Forma */}
        <div className="form-group">
          <div className="input-group mr-sm-2">
            <div className="input-group-prepend">
              <div className="input-group-text">
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="Busqueda por ubicación"
              value={query}
              onChange={this.handleQueryChange}
              onKeyDown={this.handleKeys}
              onClick={(e) => { e.target.select(); }}
              onBlur={this.handleQueryBlur}
            />
          </div>
        </div>
        {/* Lista de resultados */}
        {
          suggestions.length > 0
          && (
            <Suggestions
              suggestions={suggestions}
              handleKeys={this.handleKeys}
              onSelected={this.placeSelected}
            />
          )
        }
        <div />
      </div>
    );
  }
}

/** **************************************** */
/** Suggestions list */
/** **************************************** */

const Suggestions = ({ suggestions, handleKeys, onSelected }) => {
  const list = suggestions.map((place, index) => (
    <li key={index} tabIndex="-1" onKeyDown={handleKeys} onClick={() => { onSelected(place); }}>
      <FontAwesomeIcon icon={faMapMarkerAlt} />
      <span className="primary">{place.structured_formatting.main_text}</span>
      <span className="secondary">{place.structured_formatting.secondary_text}</span>
    </li>
  ));

  return (
    <div className="results">
      <ul id="googleAutocomplete_suggestions">
        {list}
      </ul>
    </div>
  );
};

export default GoogleAutocomplete;
