import React from "react";
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

const InfoVehiculo = ({ vehicle }) => {
    return (
        <div className="card mirai-shadow">
            <h5 className="card-header">Información general</h5>
            <div className="row card-body">
                <div className="col-12 col-md-6 dato">
                    <p className="label">ID</p>
                    <p className="value">{vehicle.id_vehicle}</p>
                </div>

                <div className="col-12 col-md-6 dato">
                    <p className="label">IMEI</p>
                    <p className="value">{vehicle.imei}</p>
                </div>

                <div className="col-12 col-md-6 dato">
                    <p className="label">Número de teléfono</p>
                    <p className="value">{vehicle.phone_number}</p>
                </div>

                <div className="col-12 col-md-6 dato">
                    <p className="label">Placas</p>
                    <p className="value">{vehicle.plate_number}</p>
                </div>

                <div className="col-12 col-md-6 dato">
                    <p className="label">Notas</p>
                    <p className="value">{vehicle.notes}</p>
                </div>

                <div className="col-12 col-md-6 dato">
                    <p className="label">Fecha de registro</p>
                    <p className="value">{moment(vehicle.createdAt).format('DD-MMMM-YYYY hh:mm a')}</p>
                </div>

                <div className="col-12 col-md-6 dato">
                    <p className="label">Concesionario</p>
                    {
                        vehicle.user
                            ? (
                                <Link className="badge badge-primary" to={`/panel/concesionarios/concesionario/detalles/${vehicle.user.id_user}`} style={{ padding: '4px 15px' }}>
                                    #{vehicle.user.id_user} {vehicle.user.name} {vehicle.user.last_name}
                                </Link>
                            )
                            : <p className="value">Sin concesionario asignado</p>
                    }
                </div>

                <div className="col-12 col-md-6 dato">
                    <p className="label">Chofer</p>
                    {
                        vehicle.driver
                            ? (
                                <Link className="badge badge-primary" to={`/panel/choferes/chofer/detalles/${vehicle.driver.id_driver}`} style={{ padding: '4px 15px' }}>
                                    #{vehicle.driver.id_driver} {vehicle.driver.name} {vehicle.driver.last_name}
                                </Link>
                            )
                            : <p className="value">Sin chofer asignado</p>
                    }
                </div>

            </div>
        </div>
    );
};

export default InfoVehiculo;
