import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './styles.css';

const logo = require('../../../assets/images/logo_w.png');

const Navbar = ({ onToggleClick = f => f }) => (
  <nav className="navbar-panel navbar navbar-expand-lg navbar-light bg-light">
    <button
      type="button"
      className="btn btn-outline-light btn-toggle"
      onClick={onToggleClick}
    >
      <FontAwesomeIcon icon={faBars} />
    </button>
    <a className="navbar-brand" href="/">
      {/* <img src={logo} alt="logo" /> */}
      DEMO
    </a>
  </nav>
);

export default Navbar;
