import React from "react";
import Table from "../../../components/table/table";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faMapMarkedAlt
} from "@fortawesome/free-solid-svg-icons";
import { cardinalFromDegrees } from "../../../lib/utils";

const TablaHistorial = ({ table, paginationHandler, mapLocate }) => {
  const columns = ["Modo", "Velocidad", "Dirección", "Fecha", "Opciones"];

  const rows = table.rows.map(row => ({
    mode: row.mode,
    speed: row.speed,
    direction: row.direction,
    date: { timestamp: row.createdAt, timestampGPS: row.timestamp_gps },
    options: { id: row.id, latitude: row.latitude, longitude: row.longitude }
  }));

  /* eslint-disable react/display-name */
  const configTable = {
    columnDef: {
      speed: val => <span>{val.toFixed(2)} km/h</span>,
      direction: val => <span>{cardinalFromDegrees(val)}</span>,
      date: val => {
        const gpsTimeArray = val.timestampGPS
          .match(/.{1,2}/g)
          .map(x => parseInt(x, 10));
        return (
          <p style={{ marginBottom: "0" }}>
            <span title="GPS time">
              {moment(
                new Date(
                  gpsTimeArray.length == 6 ?
                    Date.UTC(
                      2000 + gpsTimeArray[0],
                      gpsTimeArray[1] > 0 ? gpsTimeArray[1] - 1 : 0,
                      gpsTimeArray[2],
                      gpsTimeArray[3], // + 7, // assuming time is in -7
                      gpsTimeArray[4],
                      gpsTimeArray[5]
                    ) :
                    Number(val.timestampGPS) * 1000
                )
              ).format("DD-MMM-YYYY HH:mm:ss")}
            </span>
            <br />
            <span title="Server time" style={{ fontSize: "0.8em" }}>
              {moment(val.timestamp).format("DD-MMM-YYYY HH:mm:ss")}
            </span>
          </p>
        );
      },
      options: val => (
        <React.Fragment>
          <a
            className="btn btn-sm btn-light"
            title="Ir a Google Maps"
            href={`https://www.google.com/maps/search/?api=1&query=${val.latitude},${val.longitude}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faMapMarkedAlt} />
          </a>
          <button
            type="button"
            className="btn btn-sm btn-info"
            title="Ver en mapa"
            onClick={() => {
              mapLocate(val.id);
            }}
          >
            <FontAwesomeIcon
              icon={faMapMarkerAlt}
              style={{ marginRight: ".25rem" }}
            />
            Ver en mapa
          </button>
        </React.Fragment>
      )
    },
    columnStyle: {
      date: {
        fontSize: "0.9em",
        whiteSpace: "nowrap",
        verticalAlign: "middle"
      },
      options: {
        whiteSpace: "nowrap",
        verticalAlign: "middle"
      }
    }
  };
  /* eslint-enable react/display-name */

  return (
    <div className="tabla-historial datatable-wrapper">
      <div className="card mirai-shadow">
        <h5 className="card-header">Historial de ubicaciones</h5>
        <div className="card-body">
          {table.distance && (
            <div className="row">
              <div className="col-12 distance">
                <h5>Distancia recorrida:</h5>
                <span>{(table.distance / 1000).toFixed(4)} Km</span>
              </div>
            </div>
          )}

          <Table
            columns={columns}
            data={rows}
            configTable={configTable}
            pagination={{
              total: table.total,
              page: table.activePage,
              limit: table.size
            }}
            paginationEvent={paginationHandler}
            updateTable={() => {
              this.getData(table.activePage);
            }}
            tableClass="table-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default TablaHistorial;
