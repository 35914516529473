import Leaflet from 'leaflet';

export const markerStart = new Leaflet.icon({
  iconUrl: require('../../../../assets/images/markers/marker-online.png'),
  iconSize: [31, 45],
  iconAnchor: [15, 45],
  popupAnchor: [0, -40]
});

export const markerFinish = new Leaflet.icon({
  iconUrl: require('../../../../assets/images/markers/marker-off.png'),
  iconSize: [31, 45],
  iconAnchor: [15, 45],
  popupAnchor: [0, -40]
});

export const markerCustom = new Leaflet.icon({
  iconUrl: require('../../../../assets/images/markers/marker-offline.png'),
  iconSize: [31, 45],
  iconAnchor: [15, 45],
  popupAnchor: [0, -40]
});