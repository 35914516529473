import React from 'react';
import Mapa from './mapa/mapa';
import TablaMonitor from './tablaMonitor';
import './styles.css';

class Monitor extends React.Component {
  constructor(props) {
    super(props);
    this.mapa = React.createRef();
  }

  // to trigger map relocation from table.
  mapLocate = (id_vehicle) => {
    document.getElementsByClassName('mapa-monitor')[0].scrollIntoView({ behavior: 'smooth' });
    this.mapa.current.locateVehicle(id_vehicle);
  }

  openModal = (id_vehicle) => {
    this.mapa.current.openModal(id_vehicle);
  }

  render() {
    return (
      <div className="container monitor">
        <Mapa ref={this.mapa} />
        <div style={{ padding: '1.5rem' }} />
        <TablaMonitor mapLocate={this.mapLocate} mapModal={this.openModal} />
      </div>
    );
  }
}

export default Monitor;
