import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Navbar from '../../components/navbars/navbar/navbar';
import Sidebar from '../../components/navbars/sidebar/sidebar';
import makeRequest from '../../lib/apiService.js';
import Monitor from './monitor/monitor';
import Concesionarios from './concesionarios/concesionarios';
import Choferes from './choferes/choferes';
import Vehiculos from './vehiculos/vehiculos';
import Historial from './historial/historial';
import Alertas from './alertas/alertas'; // vehicle alerts
import AlertasList from '../../components/alerta/alerta'; // pop-up msgs
import LoadingBar from '../../components/loading/loading';
import meConnect from '../../components/redux/meConnect';
import './styles.css';
import Geocercas from './geocercas/geocercas.js';

class PanelAdmin extends React.Component {
  state = {
    toggled: false,
    notifications: 0,
  };

  componentDidMount() {
    this.getMe();
    this.autoUpdateNotifications();
  }

  toggleSidebar = () => this.setState((prevState) => ({ toggled: !prevState.toggled }));

  getMe = async () => {
    try {
      const me = await makeRequest(
        `auth/me`,
        { method: 'GET' },
      );
      this.props.setMe(me);
    } catch (error) {
      console.error(error.message);
    }
  }

  getNotifications = async () => {
    try {
      const notifications = await makeRequest(
        'notifications/unseen',
        { method: 'GET' },
      );
      this.setState({ notifications: notifications.count });
    } catch (error) {
      console.error(error.message);
    }
  }

  autoUpdateNotifications = () => {
    this.getNotifications();
    this.notificationsLoop = setInterval(this.getNotifications, 60000);
  }

  render() {
    const { toggled, notifications } = this.state;
    const { match: { url } } = this.props;

    return (
      <div className="panel-admin">
        <div className="header">
          <Navbar onToggleClick={this.toggleSidebar} />
        </div>
        <div className="panel-body">
          <LoadingBar
            show
            showBar={true}
            height="5px"
            color="#395FC2"
            backgroundColor="#56CCF2"
            wrapperBackgroundColor="#395FC2"
          />
          <div className="left">
            <Sidebar toggled={toggled} notifications={notifications} />
          </div>
          <div className="right">
            <AlertasList />
            <Switch>
              <Route exact path={`${url}`} render={() => <Redirect to={`${url}/monitor`} />} />
              <Route path={`${url}/monitor`} component={Monitor} />
              <Route path={`${url}/concesionarios`} component={Concesionarios} />
              <Route path={`${url}/choferes`} component={Choferes} />
              <Route path={`${url}/vehiculos`} component={Vehiculos} />
              <Route path={`${url}/historial`} component={Historial} />
              <Route path={`${url}/alertas`} component={Alertas} />
              <Route path={`${url}/geocercas`} component={Geocercas} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default meConnect(PanelAdmin);
