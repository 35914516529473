import { createStore, combineReducers } from 'redux';
import { alertas, loadingBar, me } from './reducers';

const initialState = {
  alertas: [],
  loadingBar: false,
  me: {},
};

const storeFactory = () => createStore(
  combineReducers({ alertas, loadingBar, me }),
  initialState
);

export default storeFactory;