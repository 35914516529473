import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import defaultConnect from '../../../components/redux/defaultConnect';
import makeRequest from '../../../lib/apiService';

moment.locale('es');

class DetallesChofer extends React.Component {
  state = {
    driver: {},
    user: null,
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { setLoading, pushError, match: { params: { id } } } = this.props;
    try {
      setLoading(true);
      const driver = await makeRequest(
        `drivers/${id}`,
        { method: 'GET' },
      );
      this.setState({ driver }, () => {
        setLoading(false);
        if (driver.id_user) {
          this.getConcesionario(driver.id_user);
        }
      });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  getConcesionario = async (id) => {
    const { setLoading, pushError } = this.props;
    try {
      setLoading(true);
      const user = await makeRequest(
        `users/${id}`,
        { method: 'GET' },
      );
      this.setState({ user });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  render() {
    const { driver, user } = this.state;

    let status = null;
    switch (driver.disabled) {
      case true:
        status = <span className="badge badge-warning">Deshabilitado</span>;
        break;
      case false:
        status = <span className="badge badge-success">Activo</span>;
        break;
      default:
        status = <span className="badge badge-dark">N/A</span>;
        break;
    }

    return (
      <div className="detalles-concesionario detalles">
        <div className="row">

          <div className="col-12 header">
            <h3>Chofer #{driver.id_driver}</h3>
            {status}
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Nombre completo</p>
            <p className="value">{`${driver.name} ${driver.last_name}`}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Correo electrónico</p>
            <p className="value">{driver.email}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Número de teléfono</p>
            <p className="value">{driver.phone_number}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Fecha de registro</p>
            <p className="value">{moment(driver.createdAt).format('DD-MMMM-YYYY hh:mm a')}</p>
          </div>

          <div className="col-12 col-md-4 dato">
            <p className="label">Concesionario</p>
            {
              user
              ? (
                <Link className="badge badge-primary" to={`/panel/concesionarios/concesionario/detalles/${driver.id_user}`}>
                  #{user.id_user} {user.name} {user.last_name}
                </Link>
              )
              : <p className="value">Sin concesionario asignado</p>
            }
          </div>

        </div>
      </div>
    );
  }
}

export default defaultConnect(DetallesChofer);
