import { getToken, isTokenExpired, refreshToken } from './authService';
import { baseUrl } from './constants';

async function createRequest(options, body) {
  const { method, headers: customHeaders } = options;
  // Obteniendo token
  let token = getToken();
  if (isTokenExpired(token)) {
    token = await refreshToken();
  }
  // headers
  const headers = new Headers({
    ...customHeaders,
    Authorization: `Bearer ${token}`,
  });
  if (method !== 'GET' && !(body instanceof FormData)) {
    headers.append('Content-Type', 'application/json');
  }
  // request
  const request = { headers, method };
  if (typeof body === 'object' && (body instanceof FormData)) {
    request.body = body;
  } else if (typeof body === 'object' && !(body instanceof FormData)) {
    request.body = JSON.stringify(body);
  }
  return request;
}

async function handleFetch(path, request) {
  let response = await fetch(path, request);
  if (!response.ok) {
    response = await response.json();
    // response = { type: "", message: "" };
    throw new Error(response.message);
  }
  if (response.status === 204) { return null; } // no content.
  if (response.headers.get('content-type').indexOf('application/json') !== -1) {
    response = await response.json();
    return response;
  }
  return response;
}

/**
 * To make a fetch request.
 * @param {*} path string.
 * @param {*} options object with method and custom headers.
 * @param {*} body optional body to send on POST and PUT.
 */
async function makeRequest(path, options, body) {
  try {
    const request = await createRequest(options, body);
    const response = await handleFetch(`${baseUrl}${path}`, request);
    return response;
  } catch (error) {
    throw error;
  }
}

export default makeRequest;