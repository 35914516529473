import React from 'react';
import Table from "../../../components/table/table";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faEdit, faTrash, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons';
import defaultConnect from '../../../components/redux/defaultConnect';
import makeRequest from '../../../lib/apiService';
import Confirmacion from '../../../components/confirmacion/confirmacion';

class TablaConcesionarios extends React.Component {
  state = {
    tabla: {
      rows: [],
      total: 0,
      activePage: 1,
      size: 10,
    },
    confirmation: {
      show: false,
      message: '',
      accept: f => f,
      acceptParams: {},
    },
  };

  componentDidMount() {
    this.getData();
  }

  getData = async (page = 1) => {
    const { tabla: { size } } = this.state;
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      const resp = await makeRequest(
        `users/?limit=${size}&page=${page}`,
        { method: 'GET' },
      );
      this.setState((prevState) => ({
        tabla: {
          ...prevState.tabla,
          rows: resp.rows,
          total: resp.count,
          activePage: page,
        }
      }), () => { setLoading(false); });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  paginationHandler = (event, value) => {
    const { tabla, tabla: { activePage } } = this.state;

    switch (event) {
      case 'page': {
        let pagina = value;
        if (pagina === 'prev') {
          pagina = (activePage - 1);
        } else if (pagina === 'next') {
          pagina = (activePage + 1);
        }
        this.getData(pagina);
        break;
      }
      case 'size':
        this.setState({ tabla: { ...tabla, size: parseInt(value, 10) } }, () => {
          this.getData(activePage);
        });
        break;
      default: break;
    }
  }

  toggleConfirmation = (confirmation) => this.setState((prevState) => ({
    confirmation: {
      ...prevState.confirmation,
      ...confirmation,
      show: !prevState.confirmation.show,
    },
  }));

  deleteConfirmation = (id) => this.toggleConfirmation({
    message: 'El concesionario será eliminado completamente del sistema.',
    accept: this.deleteConcesionario,
    acceptParams: { id },
  });

  disableConfirmation = (id, value) => this.toggleConfirmation({
    message: value ? 'El concesionario no podrá acceder al sistema.' : 'El concesionario podrá acceder al sistema.',
    accept: this.toggleDisabled,
    acceptParams: { id, value },
  });

  toggleDisabled = async () => {
    const { setLoading, pushError, pushSuccess } = this.props;
    const {
      tabla: { activePage },
      confirmation: { acceptParams: { id, value } }
    } = this.state;

    try {
      this.toggleConfirmation();
      setLoading(true);
      await makeRequest(
        `users/${id}`,
        { method: 'PUT' },
        {
          disabled: value,
        }
      );
      setLoading(false);
      pushSuccess('Se ha realizado el cambio con exito.', 3000);
      this.getData(activePage);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  deleteConcesionario = async () => {
    const { setLoading, pushError, pushSuccess } = this.props;
    const {
      tabla: { activePage },
      confirmation: { acceptParams: { id } }
    } = this.state;

    try {
      this.toggleConfirmation();
      setLoading(true);
      await makeRequest(
        `users/${id}`,
        { method: 'DELETE' },
      );
      setLoading(false);
      pushSuccess('Se ha realizado el cambio con exito.', 3000);
      this.getData(activePage);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  render() {
    const { tabla, confirmation } = this.state;
    const columns = ['ID', 'Nombre', 'Correo electrónico', 'Teléfono', 'Opciones'];

    const rows = tabla.rows.map(row => ({
      id: row.id_user,
      name: `${row.name} ${row.last_name}`,
      email: row.email,
      phone: row.phone_number,
      options: { id: row.id_user, status: row.disabled }
    }));

    const configTable = {
      columnDef: {
        id: val => (<span>#{val}</span>),
        options: val => (
          <React.Fragment>
            <Link className="btn btn-sm btn-light" to={`concesionarios/concesionario/detalles/${val.id}`}>
              <FontAwesomeIcon icon={faList} style={{ marginRight: '.25rem' }} />
              Ver más
            </Link>
            <button type="button" className="btn btn-sm btn-warning" style={{ width: '90px' }} onClick={() => { this.disableConfirmation(val.id, !val.status); }}>
              <FontAwesomeIcon icon={val.status ? faToggleOff : faToggleOn} style={{ marginRight: '.25rem' }} />
              {val.status ? 'Inactivo' : 'Activo'}
            </button>
            <Link className="btn btn-sm btn-sl-2" to={`concesionarios/concesionario/${val.id}`}>
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button type="button" className="btn btn-sm btn-danger" onClick={() => { this.deleteConfirmation(val.id); }}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </React.Fragment>
        )
      },
      columnStyle: {
        options: {
          whiteSpace: 'nowrap',
        },
      },
    };

    return (
      <div className="tabla-concesionarios">

        <Table
          columns={columns}
          data={rows}
          configTable={configTable}
          pagination={{
            total: tabla.total,
            page: tabla.activePage,
            limit: tabla.size
          }}
          paginationEvent={this.paginationHandler}
          updateTable={() => {
            this.getData(tabla.activePage);
          }}
          tableClass={"datatable-wrapper"}
        />

        {confirmation.show && <Confirmacion message={confirmation.message} accept={confirmation.accept} cancel={() => { this.toggleConfirmation(undefined); }} />}

      </div>
    );
  }
}

export default defaultConnect(TablaConcesionarios);
