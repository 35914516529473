import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TablaVehiculos from './tablaVehiculos';
import FormaVehiculo from './formaVehiculo';
import DetallesVehiculo from './detallesVehiculo';

const Vehiculos = ({ match: { url } }) => (
  <div className="container">
    <div className="row">
      <div className="col">

        <h2>Administración de Vehículos</h2>

        <ul className="sl-pills nav nav-pills">
          <li className="nav-item">
            <NavLink exact to="/panel/vehiculos" className="btn btn-sm">
              Lista de vehículos
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact to="/panel/vehiculos/vehiculo" className="btn btn-sm">
              <FontAwesomeIcon icon={faPlus} />
              Nuevo vehiculo
            </NavLink>
          </li>
        </ul>

        <div className="card mirai-shadow border-top-square">
          <div className="card-body">
            <Switch>
              <Route exact path={`${url}`} component={TablaVehiculos} />
              <Route exact path={`${url}/vehiculo`} component={FormaVehiculo} />
              <Route exact path={`${url}/vehiculo/:id`} component={FormaVehiculo} />
              <Route exact path={`${url}/vehiculo/detalles/:id`} component={DetallesVehiculo} />
            </Switch>
          </div>
        </div>

      </div>
    </div>
  </div>
);

export default Vehiculos;
