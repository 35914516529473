import React from "react";
import Table from "../../../components/table/table";
import { Link } from "react-router-dom";
import moment from "moment";
import { WafoFormInput } from "@wafo/forms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faList,
  faToggleOn,
  faToggleOff,
  faMapMarkerAlt,
  faCar,
  faEraser,
  faStreetView
} from "@fortawesome/free-solid-svg-icons";
import defaultConnect from "../../../components/redux/defaultConnect";
import Confirmacion from "../../../components/confirmacion/confirmacion";
import makeRequest from "../../../lib/apiService";
import StatusFilter from "./statusFilter";

const defaultFilters = {
  search: "",
  status: ""
};

class TablaMonitor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabla: {
        rows: [],
        total: 0,
        activePage: 1,
        size: 10
      },
      filters: defaultFilters,
      confirmation: {
        show: false,
        message: "",
        accept: f => f,
        acceptParams: {}
      }
    };

    this.autocomplete = React.createRef();
  }

  componentDidMount() {
    this.autoUpdateData();
  }

  componentWillUnmount() {
    clearInterval(this.dataLoop);
  }

  getData = async (page = 1) => {
    const {
      tabla: { size },
      filters
    } = this.state;
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      const resp = await makeRequest(
        `monitor/?limit=${size}&page=${page}` +
        `${filters.status
          ? `&${filters.status.filter}=${filters.status.value}`
          : ""
        }` +
        `${filters.search ? `&search=${filters.search}` : ""}`,
        { method: "GET" }
      );
      this.setState(
        prevState => ({
          tabla: {
            ...prevState.tabla,
            rows: resp.rows,
            total: resp.count,
            activePage: page
          }
        }),
        () => {
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  };

  autoUpdateData = () => {
    const {
      tabla: { activePage }
    } = this.state;
    this.getData(activePage);
    this.dataLoop = setInterval(() => {
      this.getData(activePage);
    }, 30000);
  };

  paginationHandler = (event, value) => {
    const {
      tabla,
      tabla: { activePage }
    } = this.state;

    switch (event) {
      case "page": {
        let pagina = value;
        if (pagina === "prev") {
          pagina = activePage - 1;
        } else if (pagina === "next") {
          pagina = activePage + 1;
        }
        this.getData(pagina);
        break;
      }
      case "size":
        this.setState(
          { tabla: { ...tabla, size: parseInt(value, 10) } },
          () => {
            this.getData(activePage);
          }
        );
        break;
      default:
        break;
    }
  };

  toggleConfirmation = confirmation =>
    this.setState(prevState => ({
      confirmation: {
        ...prevState.confirmation,
        ...confirmation,
        show: !prevState.confirmation.show
      }
    }));

  turnOnOffConfirmation = (id, value) =>
    this.toggleConfirmation({
      message: value
        ? "El vehículo será encendido y podrá funcionar a la normalidad."
        : "El vehículo será apagado (cuando su velocidad sea menor a 20km/h) y no podrá ser encendido.",
      accept: this.turnOnOffVehicle,
      acceptParams: { id, value }
    });

  turnOnOffVehicle = async () => {
    const { setLoading, pushError, pushSuccess } = this.props;
    const {
      tabla: { activePage },
      confirmation: {
        acceptParams: { id, value }
      }
    } = this.state;

    try {
      this.toggleConfirmation();
      setLoading(true);
      await makeRequest(
        `monitor/command/${id}`,
        { method: "POST" },
        {
          command: value ? "110" : "109"
        }
      );
      setLoading(false);
      pushSuccess("Se ha realizado el cambio con exito.", 3000);
      this.getData(activePage);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  };

  // Filters

  handleFilterChange = event => {
    const {
      target: { name, value }
    } = event;
    this.setState(
      prevState => ({
        tabla: {
          ...prevState.tabla,
          activePage: 1
        },
        filters: {
          ...prevState.filters,
          [name]: value
        }
      }),
      this.getData
    );
  };

  clearFilters = () =>
    this.setState({ filters: defaultFilters }, () => {
      this.autocomplete.current.clearForm();
      this.getData();
    });

  render() {
    const { tabla, filters, confirmation } = this.state;
    const { mapLocate, mapModal } = this.props;
    const columns = [
      "Clave",
      "Estado",
      "Placas",
      "Descripción",
      "Gasolina",
      "Actualización",
      "Opciones"
    ];

    const rows = tabla.rows.map(row => ({
      key: row.vehicle.key_vehicle,
      status: {
        power: row.vehicle.power,
        socket: row.socket,
        gps_signal: row.gps_signal,
        zone: row.zone,
        working: row.working
      },
      plate: row.vehicle.plate_number,
      description: row.vehicle.description,
      gasolina: {
        tank1: row.gas || "0",
        tank2: row.gas2 || "0.00"
      },
      update: { timestamp: row.timestamp, timestampGPS: row.timestampGPS },
      options: {
        id: row.vehicle.id_vehicle,
        power: row.vehicle.power,
        locations: {
          latitude: row.latitude,
          longitude: row.longitude
        }
      }
    }));

    const configTable = {
      columnDef: {
        status: val => {
          if (val.working) {
            return <span className="badge badge-primary">Trabajando</span>;
          }
          if (!val.socket) {
            return <span className="badge badge-secondary">Desconectado</span>;
          }
          if (!val.power) {
            return <span className="badge badge-danger">Apagado</span>;
          }
          if (val.socket && !val.gps_signal) {
            return <span className="badge badge-warning">Sin GPS</span>;
          }
          if (val.socket && val.gps_signal) {
            return <span className="badge badge-success">Conectado</span>;
          }
          return <span className="badge badge-dark">N/A</span>;
        },
        gasolina: val => {
          return (
            <span>{val.tank1.includes("%") ? val.tank1 : `${val.tank1}%`}</span>
          );
        },
        update: val => {
          const arr = val.timestampGPS.match(/.{1,2}/g) || [];
          const gpsTimeArray = arr.map(x => parseInt(x, 10));
          return (
            <p style={{ marginBottom: "0" }}>
              <span title="GPS time">
                {moment(
                  new Date(
                    gpsTimeArray.length == 6 ?
                      Date.UTC(
                        2000 + gpsTimeArray[0],
                        gpsTimeArray[1] > 0 ? gpsTimeArray[1] - 1 : 0,
                        gpsTimeArray[2],
                        gpsTimeArray[3], // + 7, // assuming time is in -7
                        gpsTimeArray[4],
                        gpsTimeArray[5]
                      ) :
                      Number(val.timestampGPS) * 1000
                  )
                ).format("DD-MMM-YYYY HH:mm:ss")}
              </span>
              <br />
              <span title="Server time" style={{ fontSize: "0.8em" }}>
                {moment(val.timestamp).format("DD-MMM-YYYY HH:mm:ss")}
              </span>
            </p>
          );
        },
        options: val => (
          <React.Fragment>
            <button
              type="button"
              className="btn btn-sm btn-primary"
              title="Ver más"
              onClick={() => {
                mapModal(val.id);
              }}
            >
              <FontAwesomeIcon icon={faCar} style={{ marginRight: ".25rem" }} />
              Ver más
            </button>
            <Link
              className="btn btn-sm btn-light"
              to={`vehiculos/vehiculo/detalles/${val.id}`}
            >
              <FontAwesomeIcon icon={faList} />
            </Link>
            <button
              type="button"
              className="btn btn-sm btn-info"
              title="Ver en mapa"
              onClick={() => {
                mapLocate(val.id);
              }}
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} />
            </button>
            <a
              className="btn btn-sm btn-warning"
              title="Street View"
              href={`http://maps.google.com/maps?q=&layer=c&cbll=${val.locations.latitude},${val.locations.longitude}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faStreetView} />
            </a>
            <button
              type="button"
              className={`btn btn-sm ${val.power ? "btn-success" : "btn-danger"
                }`}
              title={val.power ? "Apagar" : "Encender"}
              onClick={() => {
                this.turnOnOffConfirmation(val.id, !val.power);
              }}
            >
              <FontAwesomeIcon icon={val.power ? faToggleOn : faToggleOff} />
            </button>
          </React.Fragment>
        )
      },
      columnStyle: {
        key: { verticalAlign: "middle" },
        status: { verticalAlign: "middle" },
        plate: { verticalAlign: "middle" },
        description: { verticalAlign: "middle" },
        gasolina: { verticalAlign: "middle" },
        update: {
          fontSize: "0.9em",
          whiteSpace: "nowrap",
          verticalAlign: "middle"
        },
        options: {
          whiteSpace: "nowrap",
          verticalAlign: "middle"
        }
      }
    };

    return (
      <div className="tabla-monitor datatable-wrapper">
        <div className="card mirai-shadow">
          <h5 className="card-header">Tabla de vehículos activos</h5>
          <div className="card-body">
            <div className="row filtros">
              <WafoFormInput
                type="text"
                name="search"
                placeholder="Filtrar por clave, placas o descripción"
                customClass="col-12 col-md-6"
                value={filters.search}
                handleInputChange={this.handleFilterChange}
              />

              <StatusFilter
                ref={this.autocomplete}
                customClass="col-12 col-md-4"
                handleInputChange={this.handleFilterChange}
              />

              <div className="botones col-12 col-md-2">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={this.clearFilters}
                >
                  <FontAwesomeIcon
                    icon={faEraser}
                    style={{ marginRight: ".25rem" }}
                  />
                  Limpiar filtros
                </button>
              </div>
            </div>

            <Table
              columns={columns}
              data={rows}
              configTable={configTable}
              pagination={{
                total: tabla.total,
                page: tabla.activePage,
                limit: tabla.size
              }}
              paginationEvent={this.paginationHandler}
              updateTable={() => {
                this.getData(tabla.activePage);
              }}
              tableClass="table-sm"
            />

            {confirmation.show && (
              <Confirmacion
                message={confirmation.message}
                accept={confirmation.accept}
                cancel={() => {
                  this.toggleConfirmation(undefined);
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default defaultConnect(TablaMonitor);
