import React from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import TablaConcesionarios from './tablaConcesionarios';
import FormaConcesionario from './formaConcesionario';
import DetallesConcesionario from './detallesConcesionario';

const Concesionarios = ({ match: { url } }) => (
  <div className="container">
    <div className="row">
      <div className="col">

        <h2>Administración de Concesionarios</h2>

        <ul className="sl-pills nav nav-pills">
          <li className="nav-item">
            <NavLink exact to="/panel/concesionarios" className="btn btn-sm">
              Lista de concesionarios
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink exact to="/panel/concesionarios/concesionario" className="btn btn-sm">
              <FontAwesomeIcon icon={faPlus} />
              Nuevo concesionario
            </NavLink>
          </li>
        </ul>

        <div className="card mirai-shadow border-top-square">
          <div className="card-body">
            <Switch>
              <Route exact path={`${url}`} component={TablaConcesionarios} />
              <Route exact path={`${url}/concesionario`} component={FormaConcesionario} />
              <Route exact path={`${url}/concesionario/:id`} component={FormaConcesionario} />
              <Route exact path={`${url}/concesionario/detalles/:id`} component={DetallesConcesionario} />
            </Switch>
          </div>
        </div>

      </div>
    </div>
  </div>
);

export default Concesionarios;
