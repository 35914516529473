import uuidv4 from 'uuid/v4';
import { alerta as alertaDefault } from '../lib/constants';

/** ******************************* */
/** Actions */

export const updateLoadingBar = (loadingBar) => ({
  type: 'UPDATE_LOADING_BAR',
  loadingBar,
});

export const pushAlerta = (alerta) => ({
  type: 'NEW_ALERTA',
  alerta: {
    ...alertaDefault,
    ...alerta,
    uuid: uuidv4(),
  }
});

export const pushAlertaSuccess = (msg, timer = 0) => ({
  type: 'NEW_ALERTA',
  alerta: {
    ...alertaDefault,
    type: 'success',
    title: '¡Acción realizada con exito!',
    message: msg,
    uuid: uuidv4(),
    timer
  }
});

export const pushAlertaError = (error, timer = 0) => ({
  type: 'NEW_ALERTA',
  alerta: {
    ...alertaDefault,
    type: 'danger',
    title: '¡Ocurrio un error!',
    message: error,
    uuid: uuidv4(),
    timer
  }
});

export const removeAlerta = (uuid) => ({
  type: 'REMOVE_ALERTA',
  uuid
});

export const setMe = (me) => ({
  type: 'UPDATE_ME',
  me,
});

/** ******************************* */
/** Reducers */

export const alertas = (state = [], action) => {
  switch (action.type) {
    case 'NEW_ALERTA':
      return [
        ...state,
        action.alerta
      ];
    case 'REMOVE_ALERTA': {
      const index = state.findIndex(x => x.uuid === action.uuid);
      return [
        ...state.slice(0, index),
        ...state.slice(index + 1),
      ];
    }
    default:
      return state;
  }
};

export const loadingBar = (state = false, action) => {
  switch (action.type) {
    case 'UPDATE_LOADING_BAR':
      return action.loadingBar;
    default:
      return state;
  }
};

export const me = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_ME':
      return action.me;
    default:
      return state;
  }
};
