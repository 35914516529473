import React from 'react';
import { WafoForm, WafoFormInput, WafoFormAutocomplete } from '@wafo/forms';
import { Redirect } from 'react-router-dom';
import meConnect from '../../../components/redux/meConnect';
import makeRequest from '../../../lib/apiService';

import Mapa from './mapa';

const initialPosition = {
  position: [29.0729673, -110.9559192],
  zoom: 11,
};

class FormGeocerca extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      geocerca: {},
      redirect: false,
      map: {
        ...initialPosition,
        key: Math.random(),
        polygonPoints: []
      }
    };
  }

  async componentDidMount() {
    const { match: { params: { id } } } = this.props;
    if (id) {
      this.getGeocerca();
    }
    setTimeout(() => {
      this.forceMapUpdate();
    }, 10);
  }

  /** ****************************** */
  /** Map stuff */
  /** ****************************** */

  // Setting map ref to acces leaflet methods.
  setMapRef = (event) => {
    if (event) {
      this.map = event.leafletElement;
      return this.map;
    }
  }

  // Changes map key to force re-render
  forceMapUpdate = () => this.setState((prevState) => ({
    map: {
      ...prevState.map,
      key: Math.random(),
    },
  }));

  // Resets maps position to default
  resetMap = () => {
    this.map.flyTo(initialPosition.position, initialPosition.zoom);
  }

  handleAddPolygonPoints = (event) => this.setState((prevState) => ({
    map: {
      ...prevState.map,
      polygonPoints: [...prevState.map.polygonPoints, [event.latlng.lat, event.latlng.lng]]
    },
  }));

  handleResetPolygonPoints = () => this.setState((prevState) => ({
    map: {
      ...prevState.map,
      polygonPoints: []
    },
  }));

  calculateZoomLevel = (polygonCoordinates) => {
    const earthRadius = 6371000; // Radio de la Tierra en metros
    const numCoords = polygonCoordinates.length;
    let area = 0; // Inicializar el área en cero

    // Convertir las coordenadas geográficas a radianes
    const toRadians = (degrees) => {
      return degrees * (Math.PI / 180);
    };

    // Calcular el área del polígono
    for (let i = 0; i < numCoords; i++) {
      const p1 = polygonCoordinates[i];
      const p2 = polygonCoordinates[(i + 1) % numCoords];
      area += toRadians(p2[0] - p1[0]) * (
        2 +
        Math.sin(toRadians(p1[1])) +
        Math.sin(toRadians(p2[1]))
      );
    }

    // Multiplicar el área por el radio de la Tierra y dividir por 2
    area *= earthRadius * earthRadius / 2;

    // Retornar el área calculada
    if (Math.abs(area) < 100000) {
      return 16;
    } else if (Math.abs(area) < 1000000) {
      return 14;
    } else {
      return 11;
    }
  };

  calculatePolygonCenter = (polygonCoordinates) => {
    let totalX = 0;
    let totalY = 0;

    for (let i = 0; i < polygonCoordinates.length; i++) {
      totalX += polygonCoordinates[i][0];
      totalY += polygonCoordinates[i][1];
    }

    const centroidX = totalX / polygonCoordinates.length;
    const centroidY = totalY / polygonCoordinates.length;

    return [centroidX, centroidY];
  };

  /** ****************************** */
  /** End map stuff */
  /** ****************************** */

  getGeocerca = async () => {
    const { setLoading, pushError, match: { params: { id } } } = this.props;
    try {
      setLoading(true);
      const geofence = await makeRequest(
        `geofences/${id}`,
        { method: 'GET' },
      );

      this.setState({ geocerca: geofence });
      const polygonPoints = geofence.polygon.coordinates[0].map(point => Object.values(point).reverse())

      this.setState((prevState) => ({
        map: {
          ...prevState.map,
          key: Math.random(),
          polygonPoints
        },
      }));
      this.map.flyTo(this.calculatePolygonCenter(polygonPoints), this.calculateZoomLevel(polygonPoints));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  handleFormSubmit = async (form) => {
    const { setLoading, pushAlerta, pushError, pushSuccess, match: { params: { id } } } = this.props;

    if (!form.valid) {
      pushAlerta({
        type: 'warning',
        title: '¡Hay un problema!',
        message: 'Algunos de los campos en la forma tienen problemas.',
        timer: 3000,
      });
      return;
    }

    if (this.state.map.polygonPoints.length < 3) {
      pushAlerta({
        type: 'warning',
        title: '¡Hay un problema!',
        message: 'La geocerca no fue definida correctamente.',
        timer: 3000,
      });
      return;
    }

    setLoading(true);
    const body = {
      name: form.name.value,
      key: form.key.value,
      polygonPoints: this.state.map.polygonPoints
    };

    try {
      await makeRequest(
        `geofences${id ? `/${id}` : ''}`,
        { method: id ? 'PUT' : 'POST' },
        body
      );
      setLoading(false);
      pushSuccess('Se han guardado los cambios.', 3000);
      this.setState({ redirect: true });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  render() {
    const { geocerca, map, redirect } = this.state;

    if (redirect) {
      return <Redirect to="/panel/geocercas" />;
    }

    return (
      <div className="form-geocerca">
        <WafoForm
          key={geocerca.id_geofence}
          buttonText="Guardar cambios"
          onSubmit={this.handleFormSubmit}
          values={geocerca}
        >

          <WafoFormInput
            type="text"
            name="name"
            placeholder="Nombre"
            label="Nombre"
            customClass="col-12 col-md-8"
            validations={{ required: true, 'text-spanish': true, maxLength: 255 }}
          />

          <WafoFormInput
            type="text"
            name="key"
            placeholder="Clave"
            label="Clave"
            customClass="col-12 col-md-4"
            validations={{ required: true, 'text-spanish': true, maxLength: 255 }}
          />

          <Mapa
            map={map}
            mapRef={this.setMapRef}
            addPoint={this.handleAddPolygonPoints}
            resetMap={this.handleResetPolygonPoints}
          />

        </WafoForm>
      </div>
    );
  }
}

export default meConnect(FormGeocerca);
