import React from 'react';
import Table from "../../../components/table/table";
import { WafoFormInput, WafoFormAutocomplete } from '@wafo/forms';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faEdit, faTrash, faToggleOff, faToggleOn, faEraser } from '@fortawesome/free-solid-svg-icons';
import meConnect from '../../../components/redux/meConnect';
import makeRequest from '../../../lib/apiService';
import Confirmacion from '../../../components/confirmacion/confirmacion';

const defaultFilters = {
  search: '',
  concesionario: '',
};

class TablaVehiculos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabla: {
        rows: [],
        total: 0,
        activePage: 1,
        size: 10,
      },
      confirmation: {
        show: false,
        message: '',
        accept: f => f,
        acceptParams: {},
      },
      usersFilter: [],
      filters: defaultFilters,
    };

    this.concesionariosAutocomplete = React.createRef();
  }

  componentDidMount() {
    const { me } = this.props;
    this.getData();
    if (me.type === 1) {
      this.getConcesionariosFilter();
    }
  }

  getConcesionariosFilter = async () => {
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      const users = await makeRequest(
        `users/?limit=${999999}`,
        { method: 'GET' },
      );
      this.setState({
        usersFilter: users.rows,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  };

  getData = async (page = 1) => {
    const { tabla: { size }, filters } = this.state;
    const { setLoading, pushError } = this.props;

    try {
      setLoading(true);
      const resp = await makeRequest(
        `vehicles/?limit=${size}&page=${page}`
        + `${filters.search ? `&search=${filters.search}` : ''}`
        + `${filters.concesionario ? `&id_user=${filters.concesionario.id_user}` : ''}`,
        { method: 'GET' },
      );
      this.setState((prevState) => ({
        tabla: {
          ...prevState.tabla,
          rows: resp.rows,
          total: resp.count,
          activePage: page,
        }
      }), () => { setLoading(false); });
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  paginationHandler = (event, value) => {
    const { tabla, tabla: { activePage } } = this.state;

    switch (event) {
      case 'page': {
        let pagina = value;
        if (pagina === 'prev') {
          pagina = (activePage - 1);
        } else if (pagina === 'next') {
          pagina = (activePage + 1);
        }
        this.getData(pagina);
        break;
      }
      case 'size':
        this.setState({ tabla: { ...tabla, size: parseInt(value, 10) } }, () => {
          this.getData(activePage);
        });
        break;
      default: break;
    }
  }

  toggleConfirmation = (confirmation) => this.setState((prevState) => ({
    confirmation: {
      ...prevState.confirmation,
      ...confirmation,
      show: !prevState.confirmation.show,
    },
  }));

  disableConfirmation = (id, value) => this.toggleConfirmation({
    message: value
      ? 'El vehículo no podrá conectarse al sistema y no será mostrado en el monitor.'
      : 'El vehículo podrá conectarse al sistema y será mostrado en el monitor.',
    accept: this.toggleDisabled,
    acceptParams: { id, value },
  });

  toggleDisabled = async () => {
    const { setLoading, pushError, pushSuccess } = this.props;
    const {
      tabla: { activePage },
      confirmation: { acceptParams: { id, value } }
    } = this.state;

    try {
      this.toggleConfirmation();
      setLoading(true);
      await makeRequest(
        `vehicles/${id}`,
        { method: 'PUT' },
        {
          disabled: value,
        }
      );
      setLoading(false);
      pushSuccess('Se ha realizado el cambio con exito.', 3000);
      this.getData(activePage);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  deleteConfirmation = (id) => this.toggleConfirmation({
    message: 'El vehículo será eliminado completamente y no podrá conectarse al sistema nunca más.',
    accept: this.deleteVehicle,
    acceptParams: { id },
  });

  deleteVehicle = async () => {
    const { setLoading, pushError, pushSuccess } = this.props;
    const {
      tabla: { activePage },
      confirmation: { acceptParams: { id } }
    } = this.state;

    try {
      this.toggleConfirmation();
      setLoading(true);
      await makeRequest(
        `vehicles/${id}`,
        { method: 'DELETE' },
      );
      setLoading(false);
      pushSuccess('Se ha realizado el cambio con exito.', 3000);
      this.getData(activePage);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  turnOnOffConfirmation = (id, value) => this.toggleConfirmation({
    message: value
      ? 'El vehículo será encendido y podrá funcionar a la normalidad.'
      : 'El vehículo será apagado (cuando su velocidad sea menor a 20km/h) y no podrá ser encendido.',
    accept: this.turnOnOffVehicle,
    acceptParams: { id, value },
  });

  turnOnOffVehicle = async () => {
    const { setLoading, pushError, pushSuccess } = this.props;
    const {
      tabla: { activePage },
      confirmation: { acceptParams: { id, value } }
    } = this.state;

    try {
      this.toggleConfirmation();
      setLoading(true);
      await makeRequest(
        `monitor/command/${id}`,
        { method: 'POST' },
        {
          command: value ? '110' : '109',
        },
      );
      setLoading(false);
      pushSuccess('Se ha realizado el cambio con exito.', 3000);
      this.getData(activePage);
    } catch (error) {
      setLoading(false);
      pushError(error.message);
    }
  }

  handleFilterChange = (event) => {
    const { target: { name, value } } = event;
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [name]: value,
      }
    }), this.getData);
  }

  clearFilters = () => this.setState({ filters: defaultFilters }, () => {
    this.concesionariosAutocomplete.current.clearForm();
    this.getData();
  });

  render() {
    const { me } = this.props;
    const { tabla, confirmation, filters, usersFilter } = this.state;
    const columns = ['Clave', 'IMEI', 'Placas', 'Descripción', 'Concesionario', 'Opciones'];

    const rows = tabla.rows.map(row => ({
      key: row.key_vehicle,
      imei: row.imei,
      plate: row.plate_number,
      description: row.description,
      user: row.id_user,
      options: { id: row.id_vehicle, linked: row.linked, power: row.power, disabled: row.disabled },
    }));

    const configTable = {
      columnDef: {
        user: val => (
          <Link className="badge badge-primary" to={`/panel/concesionarios/concesionario/detalles/${val}`}>
            Concesionario #{val}
          </Link>
        ),
        options: val => (
          <React.Fragment>
            <Link className="btn btn-sm btn-light" to={`vehiculos/vehiculo/detalles/${val.id}`}>
              <FontAwesomeIcon icon={faList} style={{ marginRight: '.25rem' }} />
              Detalles
            </Link>
            <button type="button" className={`btn btn-sm ${val.power ? 'btn-success' : 'btn-danger'}`} title={val.power ? 'Apagar' : 'Encender'} onClick={() => { this.turnOnOffConfirmation(val.id, !val.power); }}>
              <FontAwesomeIcon icon={val.power ? faToggleOn : faToggleOff} />
            </button>
            <button type="button" className="btn btn-sm btn-warning" title={val.disabled ? 'Habilitar' : 'Deshabilitar'} onClick={() => { this.disableConfirmation(val.id, !val.disabled); }}>
              <FontAwesomeIcon icon={val.disabled ? faToggleOff : faToggleOn} />
            </button>
            <Link className="btn btn-sm btn-sl-2" title="Editar" to={`vehiculos/vehiculo/${val.id}`}>
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <button type="button" className="btn btn-sm btn-danger" title="Eliminar" onClick={() => { this.deleteConfirmation(val.id); }}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </React.Fragment>
        )
      },
      columnStyle: {
        imei: {
          fontSize: '0.85em',
        },
        plate: {
          fontSize: '0.85em',
          whiteSpace: 'nowrap',
        },
        options: {
          whiteSpace: 'nowrap',
          textAlign: 'right',
        },
      },
    };

    return (
      <div className="tabla-concesionarios">

        <div className="row filtros">
          <WafoFormInput
            type="text"
            name="search"
            placeholder="Filtrar por clave, placas o descripción"
            customClass="col-12 col-md-6"
            value={filters.search}
            handleInputChange={this.handleFilterChange}
          />

          <WafoFormAutocomplete
            ref={this.concesionariosAutocomplete}
            name="concesionario"
            customClass="col-12 col-md-4"
            placeholder="Filtrar por concesionario"
            items={usersFilter}
            filterItemsFN={(items, query) => items.filter(item => (
              item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || item.last_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || `${item.name} ${item.last_name}`.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1
              || (item.description && item.description.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            ))}
            customItemFN={item => (
              <p style={{ margin: '0' }}>
                <span>{item.name} {item.last_name}</span>
                <br />
                <span style={{ fontSize: '0.8em', color: '#777' }}>#{item.id_user} - {item.description}</span>
              </p>
            )}
            customInputFN={item => `${item.name} ${item.last_name}`}
            handleInputChange={this.handleFilterChange}
            extraProps={{
              disabled: me.type !== 1,
            }}
          />

          <div className="botones col-12 col-md-2">
            <button type="button" className="btn btn-light" onClick={this.clearFilters}>
              <FontAwesomeIcon icon={faEraser} style={{ marginRight: '.25rem' }} />
              Limpiar filtros
            </button>
          </div>
        </div>

        <Table
          columns={columns}
          data={rows}
          configTable={configTable}
          pagination={{
            total: tabla.total,
            page: tabla.activePage,
            limit: tabla.size
          }}
          paginationEvent={this.paginationHandler}
          updateTable={() => {
            this.getData(tabla.activePage);
          }}
          tableClass={"datatable-wrapper"}
        />

        {confirmation.show && <Confirmacion message={confirmation.message} accept={confirmation.accept} cancel={() => { this.toggleConfirmation(undefined); }} />}

      </div>
    );
  }
}

export default meConnect(TablaVehiculos);
