export function cardinalFromDegrees(d) {
  const j = (parseInt(d, 10) + 11.25) % 360;
  if (j <= 22.5) return 'Norte';
  else if (j <= 45) return 'Norte / Noroeste';
  else if (j <= 67.5) return 'Noroeste';
  else if (j <= 90) return 'Este / Noroeste';
  else if (j <= 112.5) return 'Este';
  else if (j <= 135) return 'Este / Sureste';
  else if (j <= 157.5) return 'Sureste';
  else if (j <= 180) return 'Sur / Sureste';
  else if (j <= 202.5) return 'Sur';
  else if (j <= 225) return 'Sur / Suroeste';
  else if (j <= 247.5) return 'Suroeste';
  else if (j <= 270) return 'Oeste / Suroeste';
  else if (j <= 292.5) return 'Oeste';
  else if (j <= 315) return 'Oeste / Noroeste';
  else if (j <= 337.5) return 'Noroeste';
  else return 'Norte / Noroeste';
}