import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faDesktop, faCarSide, faHistory, faUserTie, faAddressCard, faUserCircle, faBell, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { logout } from '../../../lib/authService';
import meConnect from '../../redux/meConnect';
import './styles.css';

const Sidebar = ({ toggled = false, me = {}, setMe = f => f, notifications = 0 }) => {
  const cerrarSesion = () => {
    setMe({});
    logout();
  };

  return (
    <nav className={['sidebar', toggled ? 'collapsed' : ''].join(' ')}>

      <div className="header">
        <FontAwesomeIcon icon={faUserCircle} />
        <p>
          Bienvenido,
          <br />
          {me.name} {me.last_name}
        </p>
      </div>

      <ul className="sidebar-links">
        <li>
          <NavLink to="/panel/monitor" activeClassName="active">
            <FontAwesomeIcon icon={faDesktop} />
            <span>Monitor</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/alertas" activeClassName="active">
            <FontAwesomeIcon icon={faBell} />
            <span>Alertas</span>
            <span className="badge badge-light notification-badge">{notifications}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/historial" activeClassName="active">
            <FontAwesomeIcon icon={faHistory} />
            <span>Historial</span>
          </NavLink>
        </li>
        {me.type === 1 && (
          <li>
            <NavLink to="/panel/concesionarios" activeClassName="active">
              <FontAwesomeIcon icon={faUserTie} />
              <span>Concesionarios</span>
            </NavLink>
          </li>
        )}
        <li>
          <NavLink to="/panel/choferes" activeClassName="active">
            <FontAwesomeIcon icon={faAddressCard} />
            <span>Choferes</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/vehiculos" activeClassName="active">
            <FontAwesomeIcon icon={faCarSide} />
            <span>Vehículos</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/panel/geocercas" activeClassName="active">
            <FontAwesomeIcon icon={faMapPin} />
            <span>Geocercas</span>
          </NavLink>
        </li>
      </ul>

      <ul className="sidebar-links sidebar-links-extra">
        <li>
          <a href="/login" onClick={cerrarSesion}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span>Cerrar sesión</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default withRouter(meConnect(Sidebar));
